@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.table-cell-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-error {
  background-color: #ea859669;
}

.d-none {
  display: none !important;
}

.table-cell-highlighted {
  background-color: rgba(237, 236, 245, 0.4);
}

.data_grid_header_coll {
  background-color: #f2cd17;
}

.phoneNumberInput {
  width: 120px !important;
  border: 1px solid #453f91 !important;
  color: #453f91 !important;
  padding: 12px 14px 12px 58px !important;
  border-radius: 4px !important;
}

.phoneNumberInput:hover {
  border: 1px solid #453f91 !important;
}

.input-color-picker {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  width: 80px;
  height: 40px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #0047ff;
  padding: 0;
  border: none;
  border-radius: 8px;
  margin-left: 32px;
  margin-bottom: 2px;
}

.input-color-picker::-webkit-color-swatch {
  border: 1px solid #d9d9d9;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  padding: 0;
}

.input-color-picker::-webkit-color-swatch-wrapper {
  width: 80px;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 0;
}
